export const lightTheme = {
  body: "#F6F8FF",
  text: "#363537",
  toggleBorder: "#FFF",
  red: "blue",
  color: "#4b6A9B",
  userInputColor: "#222731",
  background: "#fefefe",
  gitTitle: "#2b3442",
  profileText: "#4b6a9b",
  date: "#697c9a",
  statsBackground: "#f6f8ff",
  filterIcon:
    "brightness(0%) saturate(100%) invert(42%) sepia(15%) saturate(1476%) hue-rotate(178deg) brightness(91%) contrast(90%)",
  boxShadow: "0px 16px 30px -10px rgba(70, 96, 187, 0.198567)",
};
export const darkTheme = {
  body: "#141D2F",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  color: "#ffffff",
  userInputColor: "#ffffff",
  background: "#1E2A47",
  gitTitle: "#ffffff",
  profileText: "#ffffff",
  date: "#ffffff",
  statsBackground: "#141D2F",
  filterIcon:
    "brightness(0%) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(62deg) brightness(97%) contrast(108%);",
  boxShadow: "0",
};
